<template>
  <div>
    <b-card class="mb-1">
      <div>
        <h2>Details d'un Agent</h2>
      </div>
      <div class="text-right d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="mx-1"
          variant="primary"
          @click="
            $router.push({
              name: 'etablissements-agent-edit',
              params: { agentId: itemDetails._id },
            })
          "
        >
          <feather-icon icon="EditIcon" />
          Editer
          <span class="text-nowrap" />
        </b-button>

        <ButtonArchiver :agent-etablissement-id="agentId" />
      </div>
    </b-card>
    <h1 v-if="!isFetchingAgentDetails && !itemDetails">
      Aucun agent ne correspond à cet enregistrement
    </h1>
    <content-loader
      v-if="isFetchingAgentDetails"
      view-box="0 0 400 460"
      :speed="2"
      primary-color="#f3f3f3"
      secondary-color="#ecebeb"
    >
      <circle
        cx="29"
        cy="30"
        r="17"
      />
      <rect
        x="58"
        y="18"
        rx="2"
        ry="2"
        width="140"
        height="10"
      />
      <rect
        x="58"
        y="34"
        rx="2"
        ry="2"
        width="140"
        height="10"
      />
      <rect
        x="-7"
        y="60"
        rx="2"
        ry="2"
        width="408"
        height="132"
      />
    </content-loader>
    <b-card
      v-if="itemDetails"
      class="mb-0"
    >
      <b-row>
        <b-col sm="6">
          <h4>Détails de l'agent</h4>
          <hr>
          <p><strong>Nom : </strong>{{ itemDetails.firstname }}</p>
          <p><strong>Prenom : </strong>{{ itemDetails.lastname }}</p>
          <p><strong>Email : </strong>{{ itemDetails.email }}</p>
          <p>
            <strong>Nombre de fiches remplies : </strong>
            <b-badge
              pill
              variant="primary"
            >
              {{ forms.length || 0 }}
            </b-badge>
          </p>
          <p>
            <strong>Validation Fiches Mensuelles : </strong>
            <b-badge
              v-if="itemDetails.canValidateFicheStatMensuel"
              variant="primary"
            >
              Autorisé
            </b-badge>
            <b-badge
              v-else
              variant="danger"
            >
              Non Autorisé
            </b-badge>
          </p>
        </b-col>
        <b-col v-if="etablissementDetails">
          <h4>Etablissement associé</h4>
          <hr>
          <p>
            <strong>Nom de l'établissement : </strong>{{ etablissementDetails.nomEtablissement }}
          </p>
          <p>
            <strong>Type : </strong>{{ etablissementDetails.tyepEtablissement }}
          </p>
          <p>
            <strong>Adresse : </strong>{{ etablissementDetails.adresseEtablissement }}
          </p>
          <p>
            <strong>Téléphone(s) : </strong>{{ etablissementDetails.telephoneEtablissement }}
          </p>
          <p>
            <strong>Email Etablissement : </strong>{{ etablissementDetails.emailEtablissement }}
          </p>

          <p
            v-if="!isEtablissementUser"
            class="text-right"
          >
            <router-link
              class="underlined"
              :to="{
                name: 'etablissements-details',
                params: { etablissementId: etablissementDetails._id },
              }"
            >
              Voir établissement associé</router-link>
          </p>
        </b-col>
      </b-row>

      <hr class="my-1">

      <b-row>
        <b-col>
          <h4 class="my-2">
            Liste des formulaires créés par l'agent
          </h4>
          <b-table
            responsive
            :items="forms"
            :fields="tableColumns"
            show-empty
            empty-text="Aucun resultats"
            :busy="isLoadingTableData"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle" />
              </div>
            </template>

            <template #cell(_id)="data">
              <strong>{{ data.index + 1 }}</strong>
            </template>

            <template #cell(createdAt)="data">
              {{ convertDateToLocalString(data.item.createdAt) }}
            </template>

            <template #cell(etablissement)="data">
              {{ data.item.etablissement && data.item.etablissement.nomEtablissement }}
            </template>

            <template #cell(statut)="data">
              {{ data.item.statut }}

              <div v-if="data.item.statut">
                <div v-if="data.item.statut == 'attente'">
                  <b-badge
                    pill
                    variant="warning"
                    class="badge-glow"
                  >
                    En attente</b-badge>
                </div>
                <div v-if="data.item.statut == 'valide'">
                  <b-badge
                    pill
                    variant="success"
                    class="badge-glow"
                  >
                    Validé</b-badge>
                </div>
                <div v-if="data.item.statut == 'rejette'">
                  <b-badge
                    pill
                    variant="danger"
                    class="badge-glow"
                  >
                    Rejetté</b-badge>
                </div>
              </div>
              <div v-else>
                Fiche cliente
              </div>

            </template>

            <template #cell(actions)="data">
              <b-button
                variant="primary"
                :to="{
                  name: 'formulaires-details',
                  params: {
                    id: data.item._id,
                    model: 'forms-stats-agences',
                  },
                }"
              >
                <feather-icon icon="EyeIcon" />
                <span class="text-nowrap">Details</span>
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormFile,
  BSpinner,
  BBadge,
  BTable,
  VBTooltip,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useVuelidate from '@vuelidate/core'
import { mapActions } from 'vuex'
import vSelect from 'vue-select'
import AppLogo from '@/components/AppLogo.vue'
import RecaptchaWidget from '@/components/RecaptchaWidget.vue'
import { ContentLoader } from 'vue-content-loader'
import ButtonArchiver from '@/views/apps/utilisateurs/etablissements/agents/components/ButtonArchiver.vue'
import utilsService from '@/services/utils/utils.service'

// store module and vuex utilities
import etablissementsStroreModule from '@/store/etablissements'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'
import ListFormsComponent from './components/ListFormsComponent.vue'

export default {
  components: {
    // BSV
    BCard,
    // BLink,
    // BCardTitle,
    // BCardText,
    // BForm,
    BButton,
    // BFormInput,
    // BFormGroup,
    // BInputGroup,
    // BInputGroupAppend,
    // BFormCheckbox,
    // BFormFile,
    BBadge,
    BTable,

    // validations
    AppLogo,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    RecaptchaWidget,
    BSpinner,
    BRow,
    BCol,
    ContentLoader,
    ListFormsComponent,
    ButtonArchiver,
  },

  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    const requiredStoreModules = [
      { path: 'etablissements', module: etablissementsStroreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)
    const { isEtablissementUser, isAdminEtablissement, isAgentEtablissement } = utilsService.currentUserUtils()

    return {
      isAdminEtablissement,
      isAgentEtablissement,
      isEtablissementUser,
      requiredStoreModules,
      $v: useVuelidate({ $lazy: true }),
    }
  },
  data() {
    return {
      itemDetails: null,
      forms: [],
      isFetchingAgentDetails: false,
      isLoadingTableData: false,
      tableColumns: [
        { key: '_id', label: '#', sortable: true },
        { key: 'reference', label: 'Référence', sortable: true },
        { key: 'createdAt', label: 'Date', sortable: true },
        { key: 'etablissement', label: 'Etablissement', sortable: true },
        { key: 'statut', label: 'Statut', sortable: true },
        // { key: 'actions' },
      ],
    }
  },
  computed: {
    etablissementDetails() {
      return this.itemDetails && this.itemDetails.etablissement
    },
    agentId() {
      return this.itemDetails && this.itemDetails._id
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        this.isFetchingAgentDetails = true
        const { agentId } = val.params
        this.isLoadingTableData = true
        this.action_getAgent(agentId)
          .then(res => {
            this.itemDetails = res.data.resource
            res.data.formsCreate.map(item => {
              this.forms = this.forms.concat(item.data)
              console.log('this.forms: ', this.forms)
            })
            this.isFetchingAgentDetails = false
            this.isLoadingTableData = false
          })
          .catch(err => {
            this.isFetchingAgentDetails = false
            console.log(err)
          })
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },

  mounted() {},
  methods: {
    ...mapActions('etablissements', {
      action_getEtablissement: 'getEtablissement',
      action_getAgent: 'getAgent',
    }),
  },
}
</script>

<style lang="scss" scoped>
.img-ctn {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  .img {
    height: 100px;
    width: 100px;
    background: rgba(4, 4, 4, 0.583);
    border-radius: 5px;
  }
}
</style>
